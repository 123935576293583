<template>
    <div class="pl-make-wrap">
        <div class="module-title">温馨提示</div>
        <div class="pl-tip-box">
            <p v-for="(item, index) in tips" :key="index">{{ item }}</p>
        </div>
        <div class="module-title">我的订单</div>
        <div class="pl-search-box pl-flex">
            <div>付款时间：
                <el-date-picker v-model="param.start_paid_at" type="date" value-format="yyyy-MM-dd" placeholder="选择付款开始日期">
                </el-date-picker>
                -
                <el-date-picker v-model="param.end_paid_at" type="date" value-format="yyyy-MM-dd" placeholder="选择付款结束日期">
                </el-date-picker>
            </div>
            <div>付款方式：<el-select v-model="param.pay_type">
                    <el-option v-for="(item, index) in options" :key="index" :label="item"
                        :value="index ? index : ''"></el-option>
                </el-select></div>
            <div><el-button type="primary" @click="search" :loading="loading">搜索</el-button></div>
        </div>
        <div class="pl-text show">有 <span class="primary-color">{{ invoiceNum }}</span>
            个订单可申请开发票，开票总金额<span class="primary-color">￥{{ amount }}</span></div>
        <el-table :data="tableData" border @selection-change="handleSelectionChange" style="margin-top: 20px;">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column width="200" align="center" header-align="center" prop="sn" label="订单号">
            </el-table-column>
            <el-table-column width="200" align="center" header-align="center" prop="paid_at" label="付款时间">
            </el-table-column>
            <el-table-column align="center" header-align="center" prop="payStr" label="支付方式">
            </el-table-column>
            <el-table-column align="center" header-align="center" label="开票金额" prop="total_amount"></el-table-column>
        </el-table>
        <div class="pl-pagination">
            <el-pagination background :page-size="param.per_page" layout="total, prev, pager, next"
                @current-change="changePage" :total="param.total">
            </el-pagination>
        </div>
        <div class="pl-footer-box pl-flex">
            <div class="pl-left">
                <!-- <el-checkbox v-model="checkedAll">全选</el-checkbox> -->
                <span style="padding-left:15px;">已选择{{ ids.length }}个订单</span>
            </div>
            <div class="pl-right">
                <el-button type="primary" @click="goPage">下一步</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { invoiceList, invoiceAmount } from '@/api/invoice'
export default {
    data() {
        return {
            loading: false,
            param: {
                page: 1,
                per_page: 10,
                start_paid_at: '',
                pay_type: '',
                end_paid_at: ''
            },
            ids: [],
            checkedAll: false,
            tips: ['1.只有订单完成交付后，才能进行开票申请', '2.若选择多条订单，订单金额将合并开具在一张发票中', '3.一般情况下15个工作日可开出发票'],
            tableData: [
            ],
            options: ['全部', '微信', '支付宝', '银联'],
            amount: '',
            invoiceNum: 0
        }
    },
    methods: {
        handleSelectionChange(val) {
            let ids = []
            let amount = 0
            val.forEach(item => {
                ids.push(item.id)
                amount += item.total_amount * 1
            })
            this.ids = [...ids]
            sessionStorage.setItem('invoice_list', JSON.stringify(ids))
            sessionStorage.setItem('invoice_amount', amount)
        },
        goPage() {
            if (!this.ids.length) {
                this.$message.error('请选择订单')
                return
            }
            this.$router.push('/user/invoice/confirm')
        },
        invoiceList(fn) {
            invoiceList(this.param).then(res => {

                let { total, data } = res
                this.param.total = total || 0
                let list = data || []
                list.forEach(item => {
                    item.payStr = this.options[item.pay_type]
                })
                this.tableData = list
                fn && fn(res)
            })
        },
        changePage(v) {
            this.param.page = v
            this.invoiceList()
        },
        search() {
            this.loading = true
            this.param.page = 1
            this.invoiceList(({ data }) => {
                this.loading = false
                // if (!data.length) {
                //     this.$message('暂无数据')
                // }
            })
        }
    },
    created() {
        this.invoiceList()
        invoiceAmount().then(res => {
            this.amount = res.unbilled_order_amount
            this.invoiceNum = res.unbilled_order_total
            console.log(res)
        })
    }
}
</script>
<style lang="scss" scoped>
.pl-tip-box {
    margin: 30px 0;

    p {
        padding: 0 10px 10px;
    }
}

.pl-text {
    margin: 15px 0;
}

.show {
    display: none;
}
</style>
